import { Component } from '@angular/core';
import { UserService, ScriptLoaderService } from '../service';
import { Unsubscriber } from './unsubscriber.class';
import {
  GOOGLE_MAP_KEY,
  SELECTED_DEPARTMENT,
  SELECTED_ADDRESS,
} from '../common/constant/shared.const';
import {
  AppConsts,
  CONSTANT,
  IWheelLocation,
  IWheelLocationAddOns,
  wheelDataSetups,
} from '../common/constant/constant';
import { ServiceLocator } from '../service/base-service-locator';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store';
import { LocalStorageService } from '../common';
import { IGatewayData } from '../model/gateway.model';
import {
  IWheelCurrentSession,
  IWheelSetupAddon,
} from '../model/wheel-setup.model';
import {
  selectAddon,
  selectTableName,
} from '../store/selectors/common.selectors';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { ICartItems } from '../model/cart.model';
import {
  ILocation,
  ILoggedInUserInfo,
  IOnlineCustomer,
  ITableReservationModel,
  IValidatePromoCodeOutput,
  IVoucherCode,
  IWheelDepartment,
  IWheelPaymentMethod,
  IWheelScreenMenu,
} from '../model';
import { WheelOrderType } from '../common/enums/wheel-order-type.enum';
import moment from 'moment';
import {
  selectIsLoggedIn,
  selectLoggedInUserInfo,
} from '../store/selectors/auth.selector';
import { environment } from '../../../environments/environment';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-root',
  template: ``,
  styles: [``],
})
export class BaseComponent extends Unsubscriber {
  logoUrl: string = '';
  isLoggedIn = false;
  loggedInUser: ILoggedInUserInfo = {} as ILoggedInUserInfo;
  googleMapSrc =
    'https://maps.googleapis.com/maps/api/js?key=' +
    GOOGLE_MAP_KEY +
    '&libraries=places';

  userService: UserService;
  scriptLoaderService: ScriptLoaderService;
  localStorageService: LocalStorageService;
  store: Store<IAppState>;
  tableName: string = '';
  systemCurrency = wheelDataSetups.currency;
  banner: any;
  checkoutMessage: string;
  noProductImage: string;
  public addOn: IWheelSetupAddon = {} as IWheelSetupAddon;
  baseColor: string = '#bf1b2f';
  alternateColor: string = 'darkgrey';
  facebookLink: string;
  instagramLink: string;
  twitterLink: string;
  loginRequired: boolean = true;
  skipTimeSlot: boolean;
  hideBranding: boolean = false;
  backgroundColor: string;
  buttonTextColor: string;
  cardId: number;
  forceLogin: boolean;
  serviceSetup: boolean;
  showCurrency: boolean;
  hideSignup: boolean;
  hideMenuFilters: boolean;
  isWokHey: boolean;
  showAlias: boolean;
  outOfOperationHoursMessage : string

  constructor(store: Store<IAppState>) {
    super();
    this.logoUrl = wheelDataSetups.logoCompanyUrl;
    this.banner = wheelDataSetups.welcomeMessage;
    this.checkoutMessage = wheelDataSetups.checkoutMessage;
    this.noProductImage = wheelDataSetups.noProductImage;

    this.userService = ServiceLocator.injector.get(UserService);
    this.scriptLoaderService = ServiceLocator.injector.get(ScriptLoaderService);
    this.localStorageService = ServiceLocator.injector.get(LocalStorageService);
    this.store = store;

    this.store
      .select(selectAddon)
      .pipe(
        filter((f) => Boolean(f)),
        tap((r) => {
          this.addOn = r;
          this.facebookLink = r.FacebookLink;
          this.instagramLink = r.InstagramLink;
          this.twitterLink = r.TwitterLink;
          this.loginRequired = !r.NoLogin;
          this.forceLogin = r.ForceLogin;
          this.hideBranding = r.HideBranding;
          this.backgroundColor = r.BackgroundColor;
          this.buttonTextColor = r.ButtonTextColor;
          this.skipTimeSlot = r.SkipTimeSlot;
          this.serviceSetup = r.ServiceSetup;
          this.showCurrency = r.ShowCurrency;
          this.hideSignup = r.HideSignup;
          this.hideMenuFilters = r.HideMenuFilters;
          this.isWokHey = r.IsWokHey;
          this.showAlias = r.ShowOrderTagAlias;
          this.outOfOperationHoursMessage =
            r.OutOfOperationHoursMessage ||
            '<h3 class="ql-align-center">Outlet is not ready for Ordering!</h3>';

          if (r.BaseColor) {
            this.baseColor = r.BaseColor;
          }
          if (r.AlternateColor) {
            this.alternateColor = r.AlternateColor;
          }
          if (!this.addOn.KitchenNotAcceptMessage) {
            this.addOn.KitchenNotAcceptMessage =
              "<h6 class='ql-align-center sync-error'>We are not accepting Orders. Please call the outlet to inform.</h6>";
          }
          if (!this.addOn.OrderKitchenSyncMessage) {
            this.addOn.OrderKitchenSyncMessage =
              '<h6 class="ql-align-center sync-info">Our outlet has received order and <br /> are currently preparing your orders.</h6>';
          }

          if(!this.addOn.KitchenStatusMessage){
            this.addOn.KitchenStatusMessage =
              '<h6 class="ql-align-center">Our outlet has received order and <br /> are currently preparing your orders.</h6>';
          }

          if (!this.isWokHey) {
            if (this.backgroundColor) {
              document.body.style.backgroundColor = this.backgroundColor;
            } else {
              document.body.removeAttribute('style');
            }
          }
          environment.isMultilocation = r.MultiLocation;
        })
      )
      .subscribe();

    this.store
      .select(selectTableName)
      .pipe(
        tap((res) => {
          this.tableName = res;
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe();

    this.store
      .select(selectIsLoggedIn)
      .pipe(
        tap((res) => {
          this.isLoggedIn = res;
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe();

    this.store
      .select(selectLoggedInUserInfo)
      .pipe(
        tap((res) => {
          if (res) {
            this.loggedInUser = res;
            this.cardId = res.cardId;
          }
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  loadGoogleScript(): Promise<any> {
    return this.scriptLoaderService.loadScript(this.googleMapSrc);
  }

  get isGuest() {
    return sessionStorage.getByKey('isGuest') !== null;
  }

  get userName() {
    return this.loggedInUser?.name || '';
  }
  get emailAddress() {
    return this.loggedInUser?.email || '';
  }

  get getPhoneNumber() {
    let phone = this.loggedInUser?.phoneNumber;
    if (phone && phone.startsWith('+')) {
      phone = this.loggedInUser?.phoneNumber?.replace('+', '')?.slice(2);
    } else {
      phone = this.loggedInUser?.phoneNumber;
    }

    if (
      sessionStorage.getItem(CONSTANT.PHONE_NUMBER) &&
      sessionStorage.getItem(CONSTANT.PHONE_NUMBER) !== 'undefined'
    ) {
      return sessionStorage
        .getItem(CONSTANT.PHONE_NUMBER)
        ?.replace('+', '')
        ?.slice(2)
        ?.replace(/^\D+/g, '');
    }
    return phone?.replace(/^\D+/g, '');
  }

  tenantId(): number {
    try {
      let jsonData = sessionStorage.getItem(CONSTANT.GETWAYJSON);
      const parse = JSON.parse(jsonData) as IGatewayData;
      return parse?.TenantId ?? environment.tenantId;
    } catch (e) {
      return environment.tenantId;
    }
  }

  get tenantName(): string {
    try {
      let jsonData = sessionStorage.getItem(CONSTANT.GETWAYJSON);
      const parse = JSON.parse(jsonData) as IGatewayData;
      return (
        AppConsts.getWaysJSON.TenantName ||
        environment.selectedTenantName ||
        parse?.Code
      );
    } catch (e) {
      return AppConsts.getWaysJSON.TenantName || environment.selectedTenantName;
    }
  }
  get tenantNameStr(): string {
    try {
      let jsonData = sessionStorage.getItem(CONSTANT.GETWAYJSON);
      const parse = JSON.parse(jsonData) as IGatewayData;
      return (
        AppConsts.getWaysJSON.TenantName ||
        environment.selectedTenantName ||
        parse?.Code
      );
    } catch (e) {
      return AppConsts.getWaysJSON.Code || environment.selectedTenantName;
    }
  }

  get tenantCode(): string {
    try {
      let jsonData = sessionStorage.getItem(CONSTANT.GETWAYJSON);
      const parse = JSON.parse(jsonData) as IGatewayData;
      return parse?.Code;
    } catch (e) {
      return '';
    }
  }

  getAddress(form?: FormGroup) {
    if (sessionStorage.getItem(SELECTED_DEPARTMENT)) {
      const selectedDepartment = JSON.parse(
        sessionStorage.getItem(SELECTED_DEPARTMENT)
      );
      const isDeliveryType = selectedDepartment.wheelOrderType === 1;
      if (isDeliveryType && sessionStorage.getItem(SELECTED_ADDRESS)) {
        var address = JSON.parse(
          window.sessionStorage.getItem(SELECTED_ADDRESS)
        );
        if (form?.controls?.address1) {
          address.displayAddress = form.controls.address1.value;
        }
        if (form?.controls?.address2) {
          address.displayAddress2 = form.controls.address2.value;
        }

        return address;
      }
    }
    return '';
  }

  getReservationInfo(): ITableReservationModel {
    const resTable = JSON.parse(
      sessionStorage.getItem(CONSTANT.RESERVATION_TABLE)
    );
    if (resTable) {
      return resTable as ITableReservationModel;
    } else {
      return {
        id: 0,
        firstName: '',
        lastName: '',
        email: '',
      } as ITableReservationModel;
    }
  }

  get reservationName() {
    var dept = this.getDepartment();
    if (
      dept &&
      dept.wheelOrderType == WheelOrderType.Reservation &&
      this.getReservationInfo().id
    ) {
      return (
        this.getReservationInfo().firstName +
        ' ' +
        this.getReservationInfo().lastName
      );
    } else {
      return '';
    }
  }

  isReservationAdmin() {
    return (
      this.getReservationInfo().id > 0 &&
      this.getReservationInfo()?.id === this.getCustomerInfo()?.id
    );
  }

  getCustomerInfo(): ITableReservationModel {
    let reservationInfo: ITableReservationModel;
    let onlineCustomer: IOnlineCustomer;
    if (sessionStorage.getItem(CONSTANT.ONLINE_CUSTOMER)) {
      onlineCustomer = JSON.parse(
        sessionStorage.getItem(CONSTANT.ONLINE_CUSTOMER)
      ) as IOnlineCustomer;
    }

    if (sessionStorage.getItem(CONSTANT.RESERVATION_TABLE)) {
      reservationInfo = JSON.parse(
        sessionStorage.getItem(CONSTANT.RESERVATION_TABLE)
      ) as ITableReservationModel;
    }

    if (reservationInfo && onlineCustomer) {
      if (reservationInfo.onlineCustomerId === onlineCustomer.customerId) {
        return reservationInfo;
      } else {
        return {
          tenantId: onlineCustomer.tenantId,
          onlineCustomerId: onlineCustomer.customerId,
          firstName: onlineCustomer.customerName,
          phoneNumber: '',
          lastName: '',
          email: '',
          notes: '',
        } as ITableReservationModel;
      }
    } else {
      return {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        notes: '',
      } as ITableReservationModel;
    }
  }

  getReservationId() {
    if (sessionStorage.getItem(CONSTANT.RESERVATION_TABLE)) {
      const reservationInfo = JSON.parse(
        sessionStorage.getItem(CONSTANT.RESERVATION_TABLE)
      ) as ITableReservationModel;
      return reservationInfo?.id || 0;
    }
    return 0;
  }

  getCustomerId(): number {
    if (sessionStorage.getItem(CONSTANT.ONLINE_CUSTOMER)) {
      var js = JSON.parse(
        sessionStorage.getItem(CONSTANT.ONLINE_CUSTOMER)
      ) as IOnlineCustomer;
      return js.customerId;
    }
    return undefined;
  }

  isBookingSession() {
    const selectedDepartment = JSON.parse(
      window.sessionStorage.getItem(SELECTED_DEPARTMENT)
    );
    if (selectedDepartment)
      return selectedDepartment.wheelOrderType === WheelOrderType.Reservation;
    return false;
  }

  getLocationId() {
    if (sessionStorage.getItem(CONSTANT.LOCATION)) {
      const selLoc = JSON.parse(
        window.sessionStorage.getItem(CONSTANT.LOCATION)
      ) as ILocation;
      return selLoc.id;
    } else if (sessionStorage.getItem(SELECTED_DEPARTMENT)) {
      const selectedDepartment = JSON.parse(
        sessionStorage.getItem(SELECTED_DEPARTMENT)
      );
      return selectedDepartment.locationId;
    }
    return 0;
  }

  getDepartmentId() {
    if (sessionStorage.getItem(SELECTED_DEPARTMENT)) {
      const selectedDepartment = JSON.parse(
        window.sessionStorage.getItem(SELECTED_DEPARTMENT)
      ) as IWheelDepartment;
      return selectedDepartment.id;
    }
    return 0;
  }

  get dineTableName() {
    var dept = this.getDepartment();
    if (
      dept &&
      dept.wheelOrderType == WheelOrderType.DineIn &&
      sessionStorage.getItem(CONSTANT.TABLE_NAME)
    ) {
      return sessionStorage.getItem(CONSTANT.TABLE_NAME);
    } else {
      return '';
    }
  }

  getDepartment(): IWheelDepartment {
    if (sessionStorage.getItem(SELECTED_DEPARTMENT)) {
      const selectedDepartment = JSON.parse(
        window.sessionStorage.getItem(SELECTED_DEPARTMENT)
      ) as IWheelDepartment;
      return selectedDepartment;
    }
    return {} as IWheelDepartment;
  }

  get selectedLocationId() {
    try {
      if (sessionStorage.getItem(CONSTANT.LOCATION)) {
        const selLoc = JSON.parse(
          window.sessionStorage.getItem(CONSTANT.LOCATION)
        ) as ILocation;
        return selLoc.id;
      }
    } catch (error) {
      console.log(error.message);
    }

    return 0;
  }

  getCurrentTableId() {
    if (sessionStorage.getItem(CONSTANT.TABLE_ID)) {
      return +sessionStorage.getItem(CONSTANT.TABLE_ID);
    }
    return 0;
  }

  getCurrentTableOtp() {
    if (sessionStorage.getItem(CONSTANT.TABLE_OTP)) {
      return +sessionStorage.getItem(CONSTANT.TABLE_OTP);
    }
    return 0;
  }

  get displayOnly(): boolean {
    if (sessionStorage.getItem(CONSTANT.DISPlAY_ONLY)) {
      return sessionStorage.getItem(CONSTANT.DISPlAY_ONLY) == 'true';
    }
    return false;
  }

  validateMinimalCartAmount(cart: ICartItems) {
    const selectedDepartment = JSON.parse(
      window.sessionStorage.getItem(SELECTED_DEPARTMENT)
    );
    const isDeliveryType = selectedDepartment.wheelOrderType === 1;
    if (isDeliveryType && cart.result.deliveryFees) {
      const minimalAmount = cart.result.deliveryFees.minimumTotalAmount;
      const total =
        cart.result.frontCartTotal.totalPrice -
        cart.result.frontCartTotal.deliveryFee;
      if (total < minimalAmount) {
        return false;
      } else if (isDeliveryType && !cart.result.deliveryFees) {
        return false;
      }
    }
    return true;
  }

  generateUUID() {
    // Public Domain/MIT
    var d = new Date().getTime(); //Timestamp
    var d2 =
      (typeof performance !== 'undefined' &&
        performance.now &&
        performance.now() * 1000) ||
      0; //Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = Math.random() * 16; //random number between 0 and 16
        if (d > 0) {
          //Use timestamp until depleted
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {
          //Use microseconds since page-load if supported
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
  }

  imgError(image) {
    image.onerror = '';
    image.src = this.noProductImage;
    return true;
  }

  getCombinedDateObject(date, time) {
    let calculatedDateString: any = '';
    if (date && time) {
      let utcDate = moment.utc(date);
      let utcTime = moment.utc(time);
      calculatedDateString = moment(`${utcDate.format('YYYY-MM-DD')} ${time}`);
    }
    let finalDateTime = new Date(calculatedDateString);
    if (isNaN(finalDateTime.getTime())) return null;
    else return calculatedDateString;
  }

  getCurrentSession(): IWheelCurrentSession {
    if (sessionStorage.getItem(CONSTANT.CURRENT_SESSION)) {
      return JSON.parse(
        sessionStorage.getItem(CONSTANT.CURRENT_SESSION)
      ) as unknown as IWheelCurrentSession;
    }
    return {} as IWheelCurrentSession;
  }

  getSelectedLocation(): IWheelLocation {
    if (sessionStorage.getItem(CONSTANT.LOCATION)) {
      return JSON.parse(
        sessionStorage.getItem(CONSTANT.LOCATION)
      ) as unknown as IWheelLocation;
    }
    return {} as IWheelLocation;
  }

  getLocationAddon(locationId: number): IWheelLocationAddOns {
    const location = wheelDataSetups.wheelLocations.find(
      (f) => f.id == locationId
    );

    if (location && location.addOns) {
      const json = JSON.parse(location.addOns) as IWheelLocationAddOns;
      return json;
    }
    return {} as IWheelLocationAddOns;
  }

  isRestrictedByDeliveryZones() {
    var location = JSON.parse(
      sessionStorage.getItem(CONSTANT.LOCATION)
    ) as IWheelLocation;
    return location.restrictDeliveryByZones;
  }

  isRestrictByDistance() {
    var location = JSON.parse(
      sessionStorage.getItem(CONSTANT.LOCATION)
    ) as IWheelLocation;
    return location.distanceRestrictInMeters > 100;
  }

  getWheelScreenMenu(): IWheelScreenMenu {
    var menu = sessionStorage.getItem(CONSTANT.MENU_ID);
    if (menu && menu.length > 10) {
      var result = JSON.parse(menu) as IWheelScreenMenu;
      return result;
    }
    return {} as IWheelScreenMenu;
  }

  clearWheelScreenMenu() {
    sessionStorage.removeItem(CONSTANT.MENU_ID);
  }

  onLocDeptSelectionCleanup() {
    sessionStorage.removeItem(CONSTANT.DATE_TIME_DELIVERY);
    sessionStorage.removeItem(CONSTANT.INITIALTABLEURL);
    sessionStorage.removeItem(CONSTANT.TABLE_ID);
    sessionStorage.removeItem(SELECTED_ADDRESS);
    sessionStorage.removeItem(SELECTED_DEPARTMENT);
    sessionStorage.removeItem(CONSTANT.CURRENT_SESSION);
    sessionStorage.removeItem(CONSTANT.TABLE_NAME);
    sessionStorage.removeItem(CONSTANT.MENU_ID);
    sessionStorage.removeItem(CONSTANT.DIALOG_SHOWN);
    environment.sessionId = '';
  }

  onDeptSelectionCleanup() {
    sessionStorage.removeItem(CONSTANT.DATE_TIME_DELIVERY);
    sessionStorage.removeItem(CONSTANT.INITIALTABLEURL);
    sessionStorage.removeItem(CONSTANT.TABLE_ID);
    sessionStorage.removeItem(SELECTED_ADDRESS);
    sessionStorage.removeItem(CONSTANT.CURRENT_SESSION);
    sessionStorage.removeItem(CONSTANT.DIALOG_SHOWN);
  }

  get dateOfDelivery() {
    if (this.getDepartment()?.wheelOrderType == WheelOrderType.PickUp) {
      var dateOfDelivery = sessionStorage.getItem(CONSTANT.DATE_TIME_DELIVERY);
      if (dateOfDelivery?.length > 10) {
        dateOfDelivery = dateOfDelivery.slice(0, 10);
      }
      return dateOfDelivery;
    }

    return '';
  }

  get dateTimeOfDelivery() {
    if (this.getDepartment()?.wheelOrderType == WheelOrderType.PickUp) {
      var dateOfDelivery = sessionStorage.getItem(CONSTANT.DATE_TIME_DELIVERY);
      if (dateOfDelivery?.length > 10) {
        dateOfDelivery = dateOfDelivery;
      }
      return dateOfDelivery;
    }

    return '';
  }

  isMultiLocation(): boolean {
    const type =
      this.addOn.MultiLocation &&
      (this.getDepartment()?.wheelOrderType === WheelOrderType.PickUp ||
        this.getDepartment()?.wheelOrderType === WheelOrderType.DineIn);

    return type;
  }

  getVoucherCode(paymentMethod?: IWheelPaymentMethod): IVoucherCode {
    let promo = {} as any;

    if (sessionStorage.getItem(CONSTANT.PROMO_CODE)) {
      promo = JSON.parse(
        sessionStorage.getItem(CONSTANT.PROMO_CODE)
      ) as IValidatePromoCodeOutput;

      if (paymentMethod) {
        if (paymentMethod.systemName?.toLowerCase() === 'cash') {
          return {} as IVoucherCode;
        }
      }
    }

    if (promo.groupId) {
      return { voucherId: undefined, groupId: promo.groupId } as IVoucherCode;
    } else {
      return { voucherId: promo.id, groupId: undefined } as IVoucherCode;
    }
  }

  removeVoucher() {
    sessionStorage.removeItem(CONSTANT.PROMO_CODE);
  }

  getDistanceFromLatLonInMeters(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(lat2 - lat1);
    var dLon = this.deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) *
        Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    var m = Math.round(d * 1000); //Distance in Meters
    return m;
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  setButtonTextColor() {
    var color = this.backgroundColor
      .replace('rgb', '')
      .replace('(', '')
      .replace(')', '')
      .split(',');

    const rgb = [Number(color[0]), Number(color[1]), Number(color[2])];
    // Randomly update colours
    rgb[0] = Math.round(Math.random() * 255);
    rgb[1] = Math.round(Math.random() * 255);
    rgb[2] = Math.round(Math.random() * 255);

    const brightness = Math.round(
      (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000
    );
    this.buttonTextColor = brightness > 125 ? 'txt-black' : 'txt-white';
  }

  getButtonStyle() {
    if (this.isWokHey) {
      return { border: `solid 1px ${this.buttonTextColor}` };
    } else {
      return {};
    }
  }

  updateBodyBackground(set: boolean = false) {
    if (this.backgroundColor && set) {
      document.body.style.backgroundColor = this.backgroundColor;
    } else {
      document.body.style.backgroundColor = '#fff';
    }
  }

  appendCustomScript(script: string) {
    let scriptTag = document.createElement('script');
    scriptTag.innerHTML = script;
    document.head.appendChild(scriptTag);
  }

  getLocationLogo() {
    const location = this.getSelectedLocation();
    const addOn = location.addOns;
    const json = JSON.parse(addOn) as IWheelLocationAddOns;
    return json?.LocationLogo;
  }

  departmentHasMultiMenu(
    locations: Array<IWheelLocation>,
    locationId: number,
    departmentId: number
  ) {
    if (locations) {
      const setupLocation = locations.find((f) => f.id == locationId);
      if (setupLocation) {
        return setupLocation.wheelDepartments.find((f) => f.id == departmentId)
          ?.hasMultiMenu;
      }
    }
    return false;
  }
}
